<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<launch_nav :site="site" :preview="preview" />

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="w-100 h-100 position-absolute top-0 start-0 p-4 d-flex align-items-start overflow-auto" style="z-index: 1">

						<div class="container">

							<div class="row justify-content-center">

								<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

									<div class="card mb-4">

										<div class="card-body">

											<p class="alert alert-success small mb-4">If you need any help launching your site then contact us at <a href="mailto:support@areoi.io">support@areoi.io</a> or use the live chat and we will happily help get your site up and running for you.</p>

											<a v-if="!auth_user.on_trial" :href="download" target="_blank" class="btn btn-lg btn-secondary w-100 d-flex align-items-center justify-content-between">
												Download Site

												<span class="material-icons-outlined">download</span>
											</a>
											<div v-else>
												<button class="btn btn-lg btn-secondary w-100 d-flex align-items-center justify-content-between disabled">
													Download Site

													<span class="material-icons-outlined">download</span>
												</button>
												<p class="small mt-2 mb-0">You can't download sites while on your free trial</p>
											</div>

										</div>

										<div class="card-body border-top">

											<p class="h5">Site setup instructions</p>
											<div class="accordion" id="instructions">

												<div class="accordion-item">
													<h2 class="accordion-header" id="step-1-header">
														<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-1" aria-expanded="true" aria-controls="step-1">
															1. Get started
														</button>
													</h2>
													<div id="step-1" class="accordion-collapse collapse" aria-labelledby="step-1" data-bs-parent="#instructions">
														<div class="accordion-body">
															<p>Once you have downloaded the zip folder extract all of the files to a folder on your computer. Within the folder you should see all of the required website files and a file named <code>/{{ login.database }}.sql</code> which is the database.</p>
															<p class="mb-0">You can follow these instructions to get the site setup on your local or live server. Setting the site up is the same as any other WordPress website. We have kept these instructions generic so hopefully you should be able to get your site up and running regardless of your hosting environment.</p>
														</div>
													</div>
												</div>

												<div class="accordion-item">
													<h2 class="accordion-header" id="step-2-header">
														<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-2" aria-expanded="true" aria-controls="step-2">
															2. Upload files to server
														</button>
													</h2>
													<div id="step-2" class="accordion-collapse collapse" aria-labelledby="step-2" data-bs-parent="#instructions">
														<div class="accordion-body">
															Setup your hosting environment the same way you would any other website. Once setup FTP to the server and upload all of the files and folders from the zip file apart from the database file (<code>/{{ login.database }}.sql</code>). There are a few plugins included so this may take some time to upload.
														</div>
													</div>
												</div>

												<div class="accordion-item">
													<h2 class="accordion-header" id="step-3-header">
														<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-3" aria-expanded="false" aria-controls="step-3">
															3. Setup database
														</button>
													</h2>
													<div id="step-3" class="accordion-collapse collapse" aria-labelledby="step-3" data-bs-parent="#instructions">
														<div class="accordion-body">
															<p class="h6 mb-2">Create database</p>
															<p>While your files are uploading you can setup your database. Log on to your hosting service and open up your database manager. Create a new database named <code>{{ login.database }}</code> along with a user. Make a note of your username and password as you will need these later.</p>

															<p class="h6 mb-2">Import data</p>
															<p>Once you have created your database you need to import the generated .sql file (<code>/{{ login.database }}.sql</code>). You can do this via phpmyadmin or your usual database tool. If using phpmyadmin select the database you created then click Import and choose the .sql file from your local machine then click Go. Ignore any messages that popup and proceed to import the database.</p>

															<p class="h6 mb-2">Replace development urls</p>
															<p>Once the database has been imported you need to run a query to change any refernce to the development url to your new url. You can do this by running the below query (make sure you replace https://mydomain.com with your actual url).</p>
															<p class="p-2 w-100 overflow-auto" style="background: #f2f2f2;"><code style="display: block; white-space: pre;">
																UPDATE {{ login.database }}_options SET option_value = replace(option_value, '{{ preview }}', 'https://mydomain.com') WHERE option_name = 'home' OR option_name = 'siteurl';
																<br><br>
																UPDATE {{ login.database }}_posts SET guid = REPLACE(guid, '{{ preview }}','https://mydomain.com');
																<br><br>
																UPDATE {{ login.database }}_posts SET post_content = REPLACE(post_content, '{{ preview }}', 'https://mydomain.com');
																<br><br>
																UPDATE {{ login.database }}_postmeta SET meta_value = REPLACE(meta_value,'{{ preview }}','https://mydomain.com');
															</code></p>

															<p class="h6 mb-2">Update wp-config.php</p>
															<p class="mb-0">Finally you need to update your <code>wp-config.php</code> file with your new database credentials. Open the file and  replace <code>username_here</code> with your database username. Then replace <code>password_here</code> with your database password.</p>
														</div>
													</div>
												</div>

												<div class="accordion-item">
													<h2 class="accordion-header" id="step-4-header">
														<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-4" aria-expanded="false" aria-controls="step-4">
															4. User management
														</button>
													</h2>
													<div id="step-4" class="accordion-collapse collapse" aria-labelledby="step-4" data-bs-parent="#instructions">
														<div class="accordion-body">
															<div v-if="login.user">
																<div class="alert alert-success">
																	<p class="h6 mb-2">Don't forget your domain</p>
																	<p class="mb-0">To view your website over the internet you will need a domain name that points to your new website. We haven't covered the instructions for this here as you are best of speaking with your domain and hosting providers.</p>
																</div>
																<p>As part of the build we have setup a temporary admin user on the WordPress website. You should use this to login and create your own user then for security reasons make sure you delete the default user we created.</p>
																<p>You can access the admin with the below details (change https://yourdomain.com to your actual domain):</p>
																<p class="mb-0 p-2" style="background: #f2f2f2;">
																	<code>
																		https://yourdomain.com/wp-admin<br>
																		U: {{ login.user }}<br>
																		P: {{ login.password }}
																	</code>
																</p>
															</div>
														</div>
													</div>
												</div>

												<div class="accordion-item">
													<h2 class="accordion-header" id="step-5-header">
														<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#step-5" aria-expanded="false" aria-controls="step-5">
															5. All set!
														</button>
													</h2>
													<div id="step-5" class="accordion-collapse collapse" aria-labelledby="step-5" data-bs-parent="#instructions">
														<div class="accordion-body">
															
															<div class="alert alert-info">
																<p class="h6 mb-2">Flush permalinks</p>
																<p class="mb-0">If you find your having issues editing page content then try flushing your permalinks. You can do this by logging into your WordPress site and going to Settings > Permalinks then clicking the Save button. If your problem persists please contact us.</p>
															</div>

															<div class="alert alert-success mb-0">
																<p class="h6 mb-2">Woohoo</p>
																<p class="mb-0">After following the above instructions you should be good to go. If you are struggling to get your website setup then please don't hesitate to get in touch with our support team.</p>
															</div>
															
														</div>
													</div>
												</div>

											</div>

										</div>

									</div>

								</div>

							</div>	

						</div>

					</div>			

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import launch_nav from '@/components/launch_nav'
import site_service from '@/services/site_service'

export default {
	name: 'site.wordpress',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		launch_nav,
	},

	data() {
		return {
			loading: false,
			site: null,
			version: 0,
			login: {
				user: null,
				password:null,
				database: null
			},
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		download()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/download/'
			}

			if ( this.site ) {
				url += this.site.slug + '/' + this.site.id
			}

			return url
		},
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_wp_login()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_wp_login()
		{			
			this.reset_error()

			await site_service.wp_login( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {

				this.login = response.data

			}).catch((error) => {
				this.error = error
			})
		},
	}
}
</script>